.main-component {
  min-height: 100vh;

  .content-wrapper {
    max-width: 1200px;
    margin: 0 auto;
  
    @media screen and (min-width: 1200px) and (max-width: 1440px) {
      margin: 0 120px;
    }
  
    @media screen and (min-width: 769px) and (max-width: 1201px) {
      margin: 0 60px;
    }
  
    @media screen and (max-width: 768px) {
      margin: 0 30px;
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}