@import "../../../GlobalStyles.scss";


.home-page {
  padding: 50px 0 100px;

  @media screen and (max-width: 768px) {
    padding: 30px 0;
  }

  .video-link {
    margin-top: 10px;
    width: auto;
    cursor: pointer;
    display: inline-flex;
    align-items: center;

    img {
      margin-right: 10px;
    }

    span {
      color: $primary;
    }
  }

  .video-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(196, 196, 196, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;

    .video-wrapper {
      position: relative;

      @media screen and (max-width: 1200px) {
        width: 90%;
        height: 70%;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
        height: 100%;
      }


      iframe {
        width: 900px;
        height: 506px;
        border: none;

        @media screen and (max-width: 1200px) {
          width: 100%;
          height: 100%;
        }
      }

      .close-icon {
        position: absolute;
        top: -12px;
        left: -12px;
        background: $primary;
        width: 26px;
        height: 26px;
        display: flex;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        @media screen and (max-width: 768px) {
          top: 6px;
          left: 6px;
        }

        img {
          width: 22px;
        }
      }
    }
  }

  .grid {
    margin-top: 100px;
    display: grid;
    gap: 50px;

    .grid-cell {

      img {
        width: 100%;
      }
    }
  }

  .top-images {
    grid-template-columns: 1fr 1fr;
    margin-top: 60px;

    @media screen and (max-width: 1200px) {
      display: block;

      .grid-cell:nth-of-type(1) {
        display: flex;
        justify-content: flex-start;

        img {
          width: 60%;
        }
      }

      .grid-cell:nth-of-type(2) {
        margin-top: -10%;
        display: flex;
        justify-content: flex-end;

        img {
          width: 60%;
        }
      }
    }

    @media screen and (max-width: 768px) {

      .grid-cell:nth-of-type(1) {

        img {
          width: 75%;
        }
      }

      .grid-cell:nth-of-type(2) {
        margin-top: 0%;

        img {
          width: 75%;
        }
      }
    }
  }

  .references {
    margin: 0 auto;
    margin-top: 50px;
    max-width: 292px;

    p {
      font-size: 16px;
      line-height: 19px;
      text-align: left;
      color: rgba(53, 71, 80, 0.5);
    }

    img {
      margin-right: 30px;
      max-width: 100%;

      @media screen and (max-width: 768px) {
        margin-right: 24px;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .advantages-header {
    margin-top: 100px;
  }

  .advantages-grid {
    grid-template-columns: 1fr 2fr;
    grid-template-areas: "menu images";


    @media screen and (min-width: 1200px) {
      height: 600px;
    }
    
    @media screen and (min-width: 1300px) {
      height: 560px;
    }

    @media screen and (max-width: 1200px) {
      margin-top: 20px;
      grid-template-columns: 1fr;
      grid-template-areas: "images"
                           "menu";
      gap: 20px;
    }

    .advantages-menu {
      grid-area: menu;

      .tiles {

        @media screen and (max-width: 1200px) {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 14px 50px;
          height: 300px;
        }

        @media screen and (max-width: 768px) {
          display: grid;
          grid-template-columns: 1fr;
          height: auto;
        }

        .tile {
          margin-top: 20px;
          cursor: pointer;
          background: rgba(246, 135, 45, 0.05);
          display: flex;
          align-items: flex-start;
          border-radius: 10px;
          padding: 16px;
    
          &.tile-selected {
            background: rgba(246, 135, 45, 0.5);
          }
    
          img {
            width: 30px;
            min-width: 30px;
            margin-right: 16px;
          }

          .small-screen-time-image {
            margin-top: 20px;
            margin-left: -46px;
            width: calc(100% + 46px);
            height: 200px;
          }
    
          .tile-texts {
            
            .title {
              margin-top: 4px;
              margin-bottom: 10px;
              font-size: 20px;
              line-height: 23px;
              font-weight: 600;

              @media screen and (max-width: 768px) {
                margin-top: 5px;
                font-size: 18px;
                line-height: 21px;
              }
            }

            img {
              width: auto;
              height: auto;
              max-width: calc(100% + 46px);
            }
          }
        }
      }
    }

    .advantages-images {
      grid-area: images;
  
      .tile-image {
        max-height: 550px;
        display: flex;
        justify-content: center;
    
        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 550px;
        }
      }
    }
  }

  .swing-section {
    margin-top: 100px;
    display: flex;
    background: rgba(246, 135, 45, 0.15);
    border-radius: 10px;
    padding: 32px 0 32px 64px;
    position: relative;

    @media screen and (max-width: 1200px) {
      margin: 100px -60px 0;
      border-radius: 0;
    }

    @media screen and (max-width: 768px) {
      margin-top: 80px;
    }

    .left {
      flex: 1;

      @media screen and (max-width: 768px) {
        padding-top: 30px;
        padding-right: 60px;
      }

      h2 {
        margin-bottom: 20px;

        @media screen and (max-width: 1200px) {
          font-style: normal;
          font-weight: 500;
          font-size: 30px;
          line-height: 35px;
        }

        @media screen and (max-width: 768px) {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          max-width: 200px;
        }
      }

      div {
        font-size: 21px;

        @media screen and (max-width: 1200px) {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
        }
      }
    }

    .right {
      flex: 1;
    }

    .swing-image {
      position: absolute;
      height: 120%;
      right: 0%;
      top: -10%;

      @media screen and (max-width: 768px) {
        height: 110px;
        right: 45px;
        top: -20px;
      }
    }

    .waves-left {
      position: absolute;
      bottom: -100px;
      left: -120px;
    }

    .waves-right {
      position: absolute;
      bottom: -100px;
      right: -120px;
    }
  }

  .links-grid {
    grid-template-areas: "link-1 link-2 link-3";

    @media screen and (max-width: 1200px) {
      margin-top: 80px;
      grid-template-areas: "link-1 link-3"
                           "link-2 link-2";
    }

    .grid-cell:nth-of-type(1) {
      grid-area: link-1;
    }
    .grid-cell:nth-of-type(2) {
      grid-area: link-2;
    }
    .grid-cell:nth-of-type(3) {
      grid-area: link-3;
    }

    .title {
      font-size: 20px;
      margin-bottom: 14px;

      @media screen and (max-width: 1200px) {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
      }
    }

    .button {
      max-width: 180px;
    }
  }

  .links-section {
    padding-top: 32px;

    .link-title {
      margin-top: 32px;
      margin-bottom: 8px;
      font-size: 18px;
      font-weight: 500;
    }

    a {
      text-decoration: none;
    }

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 44px;
      text-decoration: none;

      &.button-reversed {
        background: transparent;
        border: 1px solid #F6872D;
        color: #000;
      }
    }
  }

  .contact-grid {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "form image";

    @media screen and (max-width: 1200px) {
      margin-top: 80px;
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
      grid-template-areas: "image"
                           "form";
      gap: 20px;
    }

    .contact-form-area {
      grid-area: form;
    
      p {
        margin-bottom: 40px;

        @media screen and (max-width: 768px) {
          margin-bottom: 20px;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
        }
      }
  
      .input-wrapper {
        margin-bottom: 20px;
      }
  
      .email-input {
        max-width: 300px;

        @media screen and (max-width: 768px) {
          max-width: 100%;
        }
      }
      
      .name-input {
        max-width: 300px;

        @media screen and (max-width: 768px) {
          max-width: 100%;
        }
      }
  
      .textarea-input {
        max-width: 400px;

        @media screen and (max-width: 768px) {
          max-width: 100%;
        }
      }
  
      .button {
        max-width: 180px;
        transition: all 0.3s;

        @media screen and (max-width: 768px) {
          max-width: 100%;
        }

        &.button-green {
          background: #090;
          transition: all 0.3s;
        }

        &.button-red {
          background: #F00;
          transition: all 0.3s;
        }
      }

      .captcha-statement {
        margin-top: 16px;
        font-size: 13px;
        color: rgba(53, 71, 80, 0.5);
        max-width: 400px;

        a {
          color: inherit;
        }
      }
    }
  
    .contact-form-image {
      grid-area: image;

      @media screen and (max-width: 1200px) {
        margin-top: 80px;
        display: flex;
        align-items: center;
      }

      @media screen and (max-width: 768px) {
        margin-top: 0;
        justify-content: center;

        img {
          max-width: 50%;
        }
      }
    }
  }

  .roadmap-grid {
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
      gap: 20px;
    }

    .roadmap-area {

      h3, p {
        margin-bottom: 20px;
      }
  
      .button {
        max-width: 180px;

        @media screen and (max-width: 768px) {
          max-width: 100%;
        }
      }
    }
  
    .roadmap-image {
      @media screen and (max-width: 1200px) {
        display: flex;
        align-items: center;
      }

      @media screen and (max-width: 768px) {
        justify-content: center;

        img {
          max-width: 50%;
        }
      }
    }
  }
  
}