@import "../../GlobalStyles.scss";


.menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  background: #fff;

  a {
    text-decoration: none;
    color: initial;
    font-size: 16px;
    line-height: 19px;

    &.with-background {
      background: $primary;
      color: #fff;
      padding: 8px 16px;
      border-radius: 5px;

      @media screen and (max-width: 768px) {
        position: absolute;
        right: 80px;
      }
    }

    &.bold {
      font-weight: bold;
    }
  }

  .hamburger-icon-wrapper {
    cursor: pointer;
  }

  .mobile-menu {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0 30px 32px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1;

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 80px;

      .close-icon {
        cursor: pointer;
      }
    }

    .links {

      a {
        display: block;
        font-size: 40px;
        line-height: 47px;
        margin-top: 10px;

        &.color-primary {
          color: $primary;
        }
      }
    }
  }
}