@import url('https://fonts.googleapis.com/css?family=Work+Sans&display=swap');


$primary: #F6872D;


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  font-family: 'Work Sans', sans-serif;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
  overflow-x: hidden;
}

.big-screens {
  @media screen and (max-width: 1200px) {
    display: none;
  }
}

.small-and-medium-screens {
  @media screen and (min-width: 1201px) {
    display: none;
  }
}

.small-screens {
  @media screen and (min-width: 769px) {
    display: none;
  }
}

.medium-and-big-screens {
  @media screen and (max-width: 768px) {
    display: none !important;
  }
}

.medium-screens {
  display: none;
  @media screen and (min-width: 769px) and (max-width: 1200px) {
    display: block;
  }
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 55px;
  line-height: 65px;

  @media screen and (max-width: 1200px) {
    font-size: 48px;
    line-height: 56px;
  }

  @media screen and (max-width: 768px) {
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
  }
}

h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;

  @media screen and (max-width: 1200px) {
    font-size: 40px;
    line-height: 47px;
  }

  @media screen and (max-width: 768px) {
    font-size: 22px;
    line-height: 26px;
  }
}

h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;

  @media screen and (max-width: 1200px) {
    font-size: 30px;
    line-height: 35px;
  }

  @media screen and (max-width: 768px) {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }
}

p {
  font-size: 21px;
  line-height: 25px;

  @media screen and (max-width: 1200px) {
    font-size: 16px;
    line-height: 19px;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 19px;
  }
}

.button {
  border: none;
  outline: none;
  background: $primary;
  color: #fff;
  cursor: pointer;
  height: 44px;
  width: 100%;
  border-radius: 5px;
  font-size: 14px;
}

.text-input {
  height: 44px;
  width: 100%;
  border: 1px solid #000;
  border-radius: 5px;
  background: transparent;
  padding: 0 16px;
  outline: none;

  &::placeholder {
    font-size: 14px;
    line-height: 16px;
    color: rgba(53, 71, 80, 0.5);
  }
}

.textarea-input {
  font-family: 'Work Sans', sans-serif;
  height: 250px;
  width: 100%;
  border: 1px solid #000;
  border-radius: 5px;
  background: transparent;
  padding: 16px;
  outline: none;
  resize: none;

  &::placeholder {
    font-size: 14px;
    line-height: 16px;
    color: rgba(53, 71, 80, 0.5);
  }
}

.error-message {
  color: red;
  margin-top: 20px;
}