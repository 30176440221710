.why-biztrips-page {
  padding: 50px 0 100px;

  @media screen and (max-width: 768px) {
    padding: 30px 0 60px;
  }

  .top-image {
    width: 100%;
  }

  .top-image-mobile-wrapper {
    display: flex;
    justify-content: center;

    img {
      width: auto;
    }
  }

  .features-grid {
    margin: 70px 0 140px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 50px;

    @media screen and (min-width: 1200px) {
      display: none;
    }

    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  
  .features-2-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 70px;
    margin: 70px 0 140px;

    @media screen and (max-width: 1200px) {
      margin-top: 0;
      grid-template-columns: 1fr;
      gap: 50px;
      margin: 0 0 100px;
    }

    @media screen and (max-width: 768px) {
      margin: 0 0 50px;
    }

    .features-2-grid-item {

      .grid-item-title-line {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        @media screen and (max-width: 768px) {
          display: block;
        }

        .grid-item-title-icon {
          margin-right: 10px;

          @media screen and (max-width: 768px) {
            margin-bottom: 4px;
          }
        }

        .grid-item-title-text {
          font-size: 20px;
          font-weight: 500;

          @media screen and (max-width: 1200px) {
            font-size: 18px;
          }
        }
      }

      .grid-item-text {

      }
    }
  }

  .buttons-line {
    margin: 30px 0 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
      margin: 0 0 50px;
      display: block;
    }

    .button {
      width: fit-content;
      padding: 0 30px;
      border-radius: 5px;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      &.button-secondary {
        margin-left: 50px;
        border: 1px solid #F6872D;
        background: #fff;
        color: #354750;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;

        @media screen and (max-width: 768px) {
          margin-left: 0;
          margin-top: 30px;
        }
      }
    }
  }

  .quote-section {
    margin-right: 80px;
    max-width: 600px;
    display: flex;
    align-items: flex-end;

    @media screen and (max-width: 1200px) {
      margin-right: 0;
    }

    @media screen and (max-width: 768px) {
      margin-top: 0;
      display: block;
    }

    .quote-left-area {
      flex: 1;

      .quote {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
      }

      .author {
        margin-top: 20px;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
      }
    }

    .quote-right-area {

      @media screen and (max-width: 768px) {
        margin-top: 20px;
      }

      img {
        width: 64px;
      }
    }
  }

  .demo-section {
    margin-top: 80px;
    display: flex;

    @media screen and (max-width: 768px) {
      margin-top: 20px;
    }

    .left {
      flex: 1;

      .button-wrapper {
        position: relative;
        margin-top: 130px;
        text-align: center;

        .button {
          width: 286px;
          height: 70px;
          border-radius: 5px;
        }

        .arrow-blue {
          position: absolute;
          height: 100px;
          top: -120px;
          left: calc(50% - 30px);
        }

        .arrow-yellow {
          position: absolute;
          height: 100px;
          top: 80px;
          left: calc(50% - 170px);
        }

        .arrow-red {
          position: absolute;
          height: 100px;
          top: 70px;
          left: calc(50% + 80px);
        }
      }
    }

    .right {
      flex: 1;
    }
  }
}