.login-box {
  background: rgba(246, 135, 45, 0.05);
  color: #000;
  width: 324px;
  min-height: 272px;
  align-self: start;
  padding: 28px 34px 28px;

  &.login-box-hidden {
    visibility: hidden;
  }

  .subtitle {
    margin-top: 10px;
  }

  .input-wrapper {
    margin-top: 16px;
  }

  .button {
    margin-top: 24px;
    transition: all 0.3s;

    &.validation-failed {
      background: #FF595E;
      transition: all 0.3s;
    }
  }

  .forgot-password-link {
    margin-top: 5px;
    font-size: 14px;
    color: #F6872D;
    user-select: none;
    cursor: pointer;
  }

  .captcha-statement {
    margin-top: 16px;
    font-size: 13px;
    line-height: 16px;
    color: rgba(53, 71, 80, 0.5);

    a {
      color: inherit;
      font-size: inherit;
      line-height: inherit;
      text-decoration: underline;
    }
  }

  .error-message {
    text-align: center;
  }

  .instances-wrapper {
    text-align: center;
    align-self: start;

    p {
      margin-bottom: 10px;
      font-size: 18px;
    }

    div {
      border: 1px solid #000;
      border-radius: 5px;
      width: 100%;
      padding: 10px;
      margin-bottom: 20px;
      cursor: pointer;
    }
  }
}